import React, { useEffect, useState } from 'react'
import './index.scss'
import { Button, InputNumber, Switch } from 'antd'
import useAdmin from './useAdmin'

type FieldItemProps = {
  label: string
  value: string
  values: any
  is_enabled: boolean
  onChange: (payload: any) => void
  no_of_message_for_limiter: number
  no_of_day_for_limiter: number
}

const FieldItem = ({
  label,
  value,
  values,
  is_enabled,
  onChange,
  no_of_message_for_limiter,
  no_of_day_for_limiter,
}: FieldItemProps) => {
  return (
    <div className="flex gap-2 flex-col">
      <div className="flex items-center gap-2">
        <span className="text-lg font-semibold">{label}</span>
        <Switch
          checked={is_enabled}
          onChange={(v) => onChange({ ...values, is_enabled: v, code_name: value })}
        />
      </div>
      <div>{`Limit the number of ${label} send to a particular user within a specific time period.`}</div>
      <div className=" items-center gap-2" style={{ display: is_enabled ? 'flex' : 'none' }}>
        <span>Limit to a maximum of</span>
        <InputNumber
          min={1}
          value={no_of_message_for_limiter}
          onChange={(v) => onChange({ ...values, no_of_message_for_limiter: v })}
        />
        <span>messages per</span>
        <InputNumber
          min={1}
          max={30}
          value={no_of_day_for_limiter}
          onChange={(v) => onChange({ ...values, no_of_day_for_limiter: v })}
        />
        <span>day(s)</span>
      </div>
    </div>
  )
}

export default function Admin() {
  const { settings, handleUpdateSettings } = useAdmin()
  const [values, setValues] = useState<any>([])
  const onFieldChange = (payload: {
    code_name: string
    is_enabled: boolean
    no_of_message_for_limiter: number
    no_of_day_for_limiter: number
  }) => {
    const updatedValues = values.map((item: any) => {
      if (item.code_name === payload.code_name) {
        return { ...item, ...payload }
      }
      return item
    })
    setValues(updatedValues)
  }

  useEffect(() => {
    setValues(settings)
  }, [settings])

  return (
    <div className="px-8 py-7 w-full admin-page ">
      <span className="text-3xl font-semibold">Global Frequency Capping</span>
      <div className="mt-2 text-secondary_text">
        Once a user reaches the message limit for a channel, no further messages will be sent until
        the period resets.
      </div>
      <div className="flex flex-col gap-4 bg-white py-4 rounded-lg mt-4 pt-10 relative">
        <Button
          className="absolute top-4 right-4"
          type="primary"
          onClick={() => handleUpdateSettings(values)}
          disabled={JSON?.stringify(values) === JSON?.stringify(settings)}
        >
          Apply
        </Button>
        {settings?.map((field, index: number) => (
          <div
            key={field.code_name}
            className={` px-4 border-gray-200 ${
              index === settings?.length - 1 ? '' : 'border-b pb-4'
            }`}
          >
            <FieldItem
              key={field.code_name}
              {...field}
              value={field.code_name}
              label={field.name}
              values={values?.find((item: any) => item.code_name === field.code_name)}
              onChange={onFieldChange}
              is_enabled={
                values?.find((item: any) => item.code_name === field.code_name)?.is_enabled
              }
              no_of_message_for_limiter={
                values?.find((item: any) => item.code_name === field.code_name)
                  ?.no_of_message_for_limiter
              }
              no_of_day_for_limiter={
                values?.find((item: any) => item.code_name === field.code_name)
                  ?.no_of_day_for_limiter
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}
